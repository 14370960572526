import React, { useState } from "react"

import { useHeaderColor } from "src/common/hooks"
import { Banner, Features } from "src/components/SecurityPage"
import ContactSalesModal from "src/developers/ContactSales"

import Layout from "../layout"

const SecurityPage = ({ location }) => {
  const { inverse, isChangeColor } = useHeaderColor({
    defaultInverse: false,
    elem: { selector: "#transition", inverse: false },
  })

  const headerColor = {
    primaryColor: "#F6F6FB",
    secondaryColor: "#fff",
  }

  const [isContactSalesModalOpen, setIsContactSalesModalOpen] = useState(false)
  const handleModalClose = () => setIsContactSalesModalOpen(false)
  const handleModalOpen = () => setIsContactSalesModalOpen(true)

  return (
    <Layout
      headerColor={headerColor}
      headerInverse={inverse}
      headerIsChangeColor={isChangeColor}
      location={location}
      openModal={handleModalOpen}
    >
      <Banner />
      <div
        style={{ position: "relative", top: "-196px" }}
        id="transition"
      ></div>
      <Features />
      <ContactSalesModal
        content={{ title: "Contact Sales" }}
        isOpen={isContactSalesModalOpen}
        onClose={handleModalClose}
      />
    </Layout>
  )
}

export default SecurityPage
